<template>
  <div class="automated-panel-container">
    <div class="json-container">
      <textarea
        id="jsonTextArea"
        v-model="automatedJson"
        class="json-input-area"
      />
      <div id="jsonErrorContainer" class="json-error-container" />
      <v-btn class="pretty-print-btn" color="primary" @click="prettyPrintJson">
        Pretty
      </v-btn>
    </div>
    <v-btn
      color="primary"
      style="margin-top: 10px"
      @click="saveAutomatedConfig"
    >
      Save
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BlockAutomatedPanelBuilder',
  data: () => {
    return {
      automatedJson: undefined,
      errorJson: undefined,
    };
  },
  computed: {
    ...mapGetters(['blockConfig', 'blockConfigStatus']),
  },
  watch: {
    blockConfigStatus(value) {
      if (value === 'success') {
        this.automatedJson = this.blockConfig;
        this.prettyPrintJson();
      }
    },
  },
  mounted() {
    if (this.blockConfigStatus === 'success') {
      this.automatedJson = this.blockConfig;
      this.prettyPrintJson();
    }
  },
  methods: {
    prettyPrintJson() {
      try {
        let obj = JSON.parse(this.automatedJson);
        this.automatedJson = JSON.stringify(obj, undefined, 4);
        document.getElementById('jsonErrorContainer').innerHTML = '';
      } catch (error) {
        let position = error.message.split('position ')[1];
        let character = error.message
          .split('forgeAccessToken ')[1]
          .split(' in')[0];
        this.errorJson = this.automatedJson.substr(0, position);
        document.getElementById('jsonErrorContainer').innerHTML =
          `${this.automatedJson.substr(0, position)}<span style="color:red">${character}</span>${this.automatedJson.substr(position + 1)}`;
        throw error;
      }
    },
    saveAutomatedConfig() {
      try {
        let obj = JSON.parse(this.automatedJson);
        document.getElementById('jsonErrorContainer').innerHTML = '';
        this.$store.commit('save_automated_config', { config: obj });
      } catch (error) {
        let position = error.message.split('position ')[1];
        let character = error.message
          .split('forgeAccessToken ')[1]
          .split(' in')[0];
        this.errorJson = this.automatedJson.substr(0, position);
        document.getElementById('jsonErrorContainer').innerHTML =
          `${this.automatedJson.substr(0, position)}<span style="color:red">${character}</span>${this.automatedJson.substr(position + 1)}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.automated-panel-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .json-container {
    position: relative;
    flex: 1;
    border: solid 2px var(--v-primary-base);
    border-radius: 5px;
    display: flex;

    .json-input-area {
      height: 100%;
      outline: none;
      flex: 1;
    }

    .json-error-container {
      height: 100%;
      width: 40%;
      padding: 10px 12px;
      background-color: rgb(240, 240, 240);
    }

    .pretty-print-btn {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
}
</style>
