<template>
  <div class="field-container elevation-1">
    <div class="field-input">
      <v-text-field
        v-if="field.type === 'text'"
        :label="field.label"
        :messages="getFieldMessage(field)"
        :placeholder="field.placeholder"
        :suffix="field.suffix"
        class="inputfield"
        readonly
      >
        <template v-if="field.tooltip" #prepend>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon v-on="on"> mdi-information-outline</v-icon>
            </template>
            {{ field.tooltip }}
          </v-tooltip>
        </template>
      </v-text-field>
      <v-text-field
        v-if="field.type === 'number'"
        :label="field.label"
        :max="field.max"
        :messages="getFieldMessage(field)"
        :min="field.min"
        :placeholder="field.placeholder"
        :suffix="field.suffix"
        readonly
        style="white-space: pre-line"
        type="number"
      >
        <template v-if="field.tooltip" #prepend>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon v-on="on"> mdi-information-outline</v-icon>
            </template>
            {{ field.tooltip }}
          </v-tooltip>
        </template>
      </v-text-field>
      <v-select
        v-if="field.type === 'select'"
        :items="field.items"
        :label="field.label"
        :placeholder="field.placeholder"
        :suffix="field.suffix"
        readonly
      >
        <template v-if="field.tooltip" #prepend>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon v-on="on"> mdi-information-outline</v-icon>
            </template>
            {{ field.tooltip }}
          </v-tooltip>
        </template>
      </v-select>
      <v-file-input
        v-else-if="field.type === 'document'"
        :label="field.label"
        readonly
      >
        <template v-if="field.tooltip" #prepend>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon v-on="on"> mdi-information-outline</v-icon>
            </template>
            {{ field.tooltip }}
          </v-tooltip>
        </template>
      </v-file-input>
    </div>
    <div class="field-actions">
      <div class="field-action">
        <v-btn icon @click="fieldEditDialog = true">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>
      <div class="field-action">
        <v-btn icon @click="fieldDeleteDialog = true">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </div>
    <form-field-dialog
      v-if="fieldEditDialog"
      :display-dialog="fieldEditDialog"
      :edited-field="field"
      @closeDialog="fieldEditDialog = false"
      @updateField="updateField"
    />
    <delete-dialog
      :dialog="fieldDeleteDialog"
      :title="`Are you sure you want to remove field '${field.label}'`"
      @closeDialog="fieldDeleteDialog = false"
      @deleteAction="deleteField"
    />
  </div>
</template>

<script>
import DeleteDialog from '@/components/DeleteDialog';
import FormFieldDialog from '@/components/WorkflowManagement/Builder/Forms/FormFieldDialog';

export default {
  name: 'FormFieldCard',
  components: { FormFieldDialog, DeleteDialog },
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      fieldDeleteDialog: false,
      fieldEditDialog: false,
      fieldRules: {
        required: (value) => !!value || 'Required.',
        inRange(min, max, value) {
          if (min !== undefined && max !== undefined) {
            return (
              (min <= value && value <= max) ||
              `Number should be between ${min} and ${max}`
            );
          } else {
            return true;
          }
        },
      },
    };
  },
  computed: {},
  methods: {
    getFieldMessage(field) {
      let messages = [];
      if (field.default !== undefined) {
        messages.push(`default: ${field.default} \n`);
      }
      if (field.min !== undefined) {
        messages.push(`minimum: ${field.min} \n`);
      }
      if (field.max !== undefined) {
        messages.push(`maximum: ${field.max} \n`);
      }
      return messages.join(``);
    },
    deleteField() {
      this.$emit('deleteField', this.field);
    },
    updateField(field) {
      this.$emit('updateField', field);
    },
  },
};
</script>

<style lang="scss" scoped>
.field-container {
  display: flex;
  padding: 10px 12px;

  .field-input {
    flex: 1;
  }

  .field-actions {
    margin-left: 7px;
    display: flex;
    flex-direction: column;

    .field-action {
      flex: 1;
      display: flex;
      justify-content: center;
      align-content: center;
    }
  }
}
</style>
