<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-tabs v-model="tab" color="primary" grow>
      <v-tab>Block</v-tab>
      <v-tab>Workflow</v-tab>
      <v-tab>Collection</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card>
          <v-form ref="form" @submit.prevent>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12" sm="6">
                    <v-text-field
                      v-model="block.name"
                      :rules="[rules.required]"
                      filled
                      label="Name"
                    />
                    <v-text-field
                      v-model="block.description"
                      :rules="[rules.required]"
                      filled
                      label="Description"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
          <v-card-actions>
            <v-spacer />
            <v-btn color="error" text @click="closeDialog"> Cancel</v-btn>
            <v-btn color="primary" @click="createBlockNode"> Create</v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <v-form ref="form" @submit.prevent>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12" sm="6">
                    <v-select
                      v-model="workflowId"
                      :items="myWorkflows"
                      :rules="[rules.required]"
                      filled
                      item-text="name"
                      item-value="id"
                      label="Workflow"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
          <v-card-actions>
            <v-spacer />
            <v-btn color="error" text @click="closeDialog"> Cancel</v-btn>
            <v-btn color="primary" @click="createWorkflowNode"> Create</v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <v-form ref="form" @submit.prevent>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12" sm="6">
                    <v-select
                      v-model="selectedCollection"
                      :items="collections"
                      :rules="[rules.required]"
                      filled
                      item-text="name"
                      item-value="id"
                      label="Collection"
                      @change="fetchWorkflowInCollection"
                    />
                  </v-col>
                  <v-col cols="12" md="12" sm="6">
                    <v-select
                      v-model="selectedCollectionWorkflow"
                      :disabled="selectedCollection === undefined"
                      :items="collectionWorkflows"
                      :rules="[rules.required]"
                      filled
                      item-text="name"
                      item-value="id"
                      label="Workflow"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
          <v-card-actions>
            <v-spacer />
            <v-btn color="error" text @click="closeDialog"> Cancel</v-btn>
            <v-btn color="primary" @click="createWorkflowNode"> Create</v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { getWorkflows } from '@/services/api/workflows.api';
import {
  getCollections,
  getCollectionWorkflows,
} from '@/services/api/collection';

export default {
  name: 'CreateNodeDialog',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      tab: 0,
      workflowId: undefined,
      block: {
        name: undefined,
        description: undefined,
      },
      rules: {
        required: (value) => !!value || 'Required.',
      },
      workflows: [],
      collections: [],
      selectedCollection: undefined,
      collectionWorkflows: [],
      selectedCollectionWorkflow: undefined,
    };
  },
  computed: {
    ...mapGetters(['builderNodes', 'builderWorkflow']),
    myWorkflows() {
      if (this.builderWorkflow !== undefined) {
        return this.workflows.filter(
          (workflow) => workflow.id !== this.builderWorkflow.id
        );
      } else {
        return this.workflows;
      }
    },
  },
  mounted() {
    getWorkflows().then((workflows) => {
      this.workflows = workflows;
    });
    getCollections().then((collections) => {
      this.collections = collections;
    });
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog');
    },
    fetchWorkflowInCollection() {
      getCollectionWorkflows(this.selectedCollection).then((workflows) => {
        this.collectionWorkflows = workflows;
      });
    },
    createWorkflowNode() {
      let node = {
        workflow: undefined,
      };
      // set correct workflow id
      if (this.tab === 1) {
        node.workflow = this.workflowId;
      } else if (this.tab === 2) {
        node.workflow = this.selectedCollectionWorkflow;
      }
      // check if its the first nodeId within the section
      if (this.builderNodes.length === 0) {
        node.type = 'start';
      }

      this.$store
        .dispatch('createNode', {
          workflowId: this.$route.params.workflowId,
          node: node,
        })
        .then(() => {
          this.block.name = undefined;
          this.block.description = undefined;
          this.workflowId = undefined;
          this.$emit('closeDialog');
        });
    },
    createBlockNode() {
      this.$store
        .dispatch('createBlock', {
          workflowId: this.$route.params.workflowId,
          block: this.block,
        })
        .then((block) => {
          let node = {
            block: block.id,
          };
          // check if its the first nodeId within the section
          if (this.builderNodes.length === 0) {
            node.type = 'start';
          }
          this.$store
            .dispatch('createNode', {
              workflowId: this.$route.params.workflowId,
              node: node,
            })
            .then(() => {
              this.block.name = undefined;
              this.block.description = undefined;
              this.workflowId = undefined;
              this.$emit('closeDialog');
            });
        });
    },
  },
};
</script>
