<template>
  <v-dialog
    v-model="displayDialog"
    max-width="500px"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title>
        <span class="headline"> Create Block </span>
      </v-card-title>
      <v-form ref="form" @submit.prevent>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="6">
                <v-text-field
                  v-model="dialogBlock.name"
                  :rules="[rules.required]"
                  clearable
                  filled
                  label="Name"
                />
                <v-text-field
                  v-model="dialogBlock.description"
                  clearable
                  filled
                  label="Description"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>

      <v-card-actions>
        <v-spacer />
        <v-btn color="error" text @click="closeDialog"> Cancel </v-btn>
        <v-btn color="primary" @click="updateBlock"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BlockDialog',
  props: {
    displayDialog: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      dialogBlock: {
        name: undefined,
        description: undefined,
      },
      rules: {
        required: (value) => !!value || 'Required.',
      },
    };
  },
  mounted() {
    this.dialogBlock = Object.assign({}, this.block);
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog');
    },
    updateBlock() {
      let body = {
        name: this.dialogBlock.name,
        description: this.dialogBlock.description,
      };
      this.$store
        .dispatch('updateBlock', {
          workflowId: this.$route.params.workflowId,
          blockId: this.block.id,
          body,
        })
        .then(this.closeDialog);
    },
  },
};
</script>

<style scoped></style>
