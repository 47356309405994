var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.builderBlock)?_c('div',{staticClass:"block-builder-container"},[_c('div',{staticClass:"ant-header",staticStyle:{"position":"relative","display":"flex","align-items":"center"}},[_c('v-btn',{staticClass:"elevation-0",attrs:{"color":"transparent","small":""},on:{"click":_vm.navigateToWorkflow}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-chevron-left")]),_vm._v(" workflow ")],1),_c('span',{staticStyle:{"flex":"1"}},[_vm._v(_vm._s(_vm.builderBlock.name))]),_c('div',{staticClass:"block-builder-actions"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({on:{"click":_vm.saveBlockConfig}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.isBlockSaved ? 'mdi-content-save' : 'mdi-content-save-outline')+" ")])]}}],null,false,3916514343)},[_c('span',[_vm._v("Save block configuration")])])],1),(_vm.blockConfigStatus === 'loading')?_c('div',{staticClass:"d-flex justify-center align-center fill-height"},[_c('ant-loading')],1):_vm._e()],1),_c('div',{staticClass:"block-config-container"},[_c('div',{staticClass:"ant-glass-background block-config-navigation"},[_c('v-select',{attrs:{"items":['manual', 'automated'],"dense":"","filled":"","label":"Type"},on:{"change":_vm.setBlockType},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.blockType === 'manual')?_c('div',{staticClass:"block-config-template"},[_c('div',{staticClass:"template-panel",class:{
              active:
                _vm.panelToConfigure.vertical === 'top' &&
                _vm.panelToConfigure.horizontal === 'left',
            },on:{"click":function($event){_vm.panelToConfigure = { vertical: 'top', horizontal: 'left' }}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.getPanelIcon(_vm.blockConfig.panels.left.top.type))+" ")]),_c('div',{staticClass:"panel-type"},[_vm._v(" "+_vm._s(_vm.getPanelType(_vm.blockConfig.panels.left.top.type))+" ")])],1),_c('div',{staticClass:"template-panel",class:{
              active:
                _vm.panelToConfigure.vertical === 'top' &&
                _vm.panelToConfigure.horizontal === 'right',
            },on:{"click":function($event){_vm.panelToConfigure = { vertical: 'top', horizontal: 'right' }}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.getPanelIcon(_vm.blockConfig.panels.right.top.type))+" ")]),_c('div',{staticClass:"panel-type"},[_vm._v(" "+_vm._s(_vm.getPanelType(_vm.blockConfig.panels.right.top.type))+" ")])],1),_c('div',{staticClass:"template-panel",class:{
              active:
                _vm.panelToConfigure.vertical === 'bottom' &&
                _vm.panelToConfigure.horizontal === 'left',
            },on:{"click":function($event){_vm.panelToConfigure = { vertical: 'bottom', horizontal: 'left' }}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.getPanelIcon(_vm.blockConfig.panels.left.bottom.type))+" ")]),_c('div',{staticClass:"panel-type"},[_vm._v(" "+_vm._s(_vm.getPanelType(_vm.blockConfig.panels.left.bottom.type))+" ")])],1),_c('div',{staticClass:"template-panel",class:{
              active:
                _vm.panelToConfigure.vertical === 'bottom' &&
                _vm.panelToConfigure.horizontal === 'right',
            },on:{"click":function($event){_vm.panelToConfigure = { vertical: 'bottom', horizontal: 'right' }}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.getPanelIcon(_vm.blockConfig.panels.right.bottom.type))+" ")]),_c('div',{staticClass:"panel-type"},[_vm._v(" "+_vm._s(_vm.getPanelType(_vm.blockConfig.panels.right.bottom.type))+" ")])],1)]):_vm._e()]),(_vm.blockType === 'manual')?_c('v-btn',{staticStyle:{"width":"100%","margin-top":"20px"},attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.resetPanel()}}},[_vm._v(" Clear panel ")]):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"ant-glass-background block-config-panel"},[(
            _vm.blockType === 'manual' &&
            _vm.panelToConfigure.horizontal &&
            _vm.panelToConfigure.vertical
          )?_c('block-manual-panel-builder',{attrs:{"selected-panel":_vm.panelToConfigure}}):_vm._e(),(_vm.blockType === 'automated')?_c('block-automated-panel-builder'):_vm._e()],1)])],1),_c('v-dialog',{key:"save-changes",attrs:{"width":"500px"},model:{value:(_vm.unsavedDialog),callback:function ($$v) {_vm.unsavedDialog=$$v},expression:"unsavedDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" Do you want to save your changes? ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.navigateWithoutSave}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.saveBlockConfig}},[_vm._v(" save")])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }