<template>
  <div>
    <v-btn
      color="primary"
      style="width: 100%; margin-bottom: 10px"
      @click="formSectionDialog = true"
    >
      Add Section
    </v-btn>

    <v-tabs
      v-if="sections.length > 0"
      v-model="sectionTab"
      background-color="primary"
      grow
    >
      <v-tab v-for="section in sections" :key="section.id">
        {{ section.name }}
        <div>
          <v-icon style="margin-right: 10px" @click.stop="editSection(section)">
            mdi-pencil
          </v-icon>
          <v-icon @click.stop="removeSection(section)"> mdi-delete</v-icon>
        </div>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="sectionTab">
      <v-tab-item v-for="section in sections" :key="section.id">
        <v-row class="flex-wrap d-flex" style="margin: 0">
          <v-col
            v-for="field in section.fields"
            :key="field.id"
            class="lg-4"
            cols="3"
          >
            <form-field-card
              :field="field"
              @deleteField="deleteField"
              @updateField="updateField"
            />
          </v-col>
          <v-col class="lg-4" cols="3">
            <div class="add-field-btn elevation-1">
              <v-btn color="primary" @click="formFieldDialog = true">
                <v-icon left> mdi-plus-circle-outline</v-icon>
                Add Field
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <form-field-dialog
          v-if="formFieldDialog"
          :display-dialog="formFieldDialog"
          :edited-field="editedField"
          @closeDialog="
            formFieldDialog = false;
            editedField = undefined;
          "
          @createField="createField"
        />
      </v-tab-item>
    </v-tabs-items>

    <form-section-dialog
      :display-dialog="formSectionDialog"
      :edited-section="editedSection"
      @closeDialog="
        formSectionDialog = false;
        editedSection = undefined;
      "
      @createSection="createSection"
      @updateSection="updateSection"
    />

    <delete-dialog
      v-if="deletedSection"
      :dialog="formSectionDeleteDialog"
      :title="`Are you sure you want to remove section '${deletedSection.name}' and all its fields`"
      @closeDialog="
        formSectionDeleteDialog = false;
        deletedSection = undefined;
      "
      @deleteAction="deleteSection"
    />
  </div>
</template>

<script>
import DeleteDialog from '@/components/DeleteDialog';
import FormSectionDialog from '@/components/WorkflowManagement/Builder/Forms/FormSectionDialog';
import FormFieldDialog from '@/components/WorkflowManagement/Builder/Forms/FormFieldDialog';
import FormFieldCard from '@/components/WorkflowManagement/Builder/Forms/FormFieldCard';

export default {
  name: 'FormBuilder',
  components: {
    FormFieldCard,
    FormFieldDialog,
    FormSectionDialog,
    DeleteDialog,
  },
  props: {
    configSections: {
      type: Array,
      default: undefined,
    },
  },
  data: () => {
    return {
      sectionTab: undefined,
      sections: [],

      formSectionDialog: false,
      formSectionDeleteDialog: false,
      editedSection: undefined,
      deletedSection: undefined,

      formFieldDialog: false,
      formFieldDeleteDialog: false,
      editedField: undefined,
      deletedField: undefined,
    };
  },
  mounted() {
    if (this.configSections !== undefined) {
      this.sections = [...this.configSections];
    }
  },
  methods: {
    editSection(section) {
      this.editedSection = section;
      this.formSectionDialog = true;
    },
    removeSection(section) {
      this.deletedSection = section;
      this.formSectionDeleteDialog = true;
    },
    createSection(section) {
      this.sections.push(section);
    },
    updateSection(section) {
      let index = this.sections.findIndex((item) => item.id === section.id);
      this.sections[index] = Object.assign({}, section);
      this.sections = [...this.sections];
    },
    deleteSection() {
      let index = this.sections.findIndex(
        (item) => item.id === this.deletedSection.id
      );
      this.sections.splice(index, 1);
      this.deletedSection = undefined;
    },
    createField(field) {
      this.sections[this.sectionTab].fields.push(field);
    },
    updateField(field) {
      let index = this.sections[this.sectionTab].fields.findIndex(
        (item) => item.id === field.id
      );
      this.sections[this.sectionTab].fields[index] = Object.assign({}, field);
      this.sections[this.sectionTab].fields = [
        ...this.sections[this.sectionTab].fields,
      ];
    },
    deleteField(field) {
      let index = this.sections[this.sectionTab].fields.findIndex(
        (item) => item.id === field.id
      );
      this.sections[this.sectionTab].fields.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-field-btn {
  display: inline-flex;
  padding: 10px 12px;
}
</style>
