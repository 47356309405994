var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ant-glass-background build-container",attrs:{"id":"grid"}},[(_vm.builderStatus === 'loading')?_c('v-progress-linear',{attrs:{"color":"primary","indeterminate":""}}):_vm._e(),_c('div',{staticClass:"grid-container"},[_c('svg',{attrs:{"id":"grid-svg-container","width":_vm.svgWidth + 'px',"height":"100%"}},[_c('defs',[_c('marker',{attrs:{"id":"arrowhead","fill":_vm.$vuetify.theme.themes.light.primary,"markerHeight":"7","markerWidth":"10","orient":"auto","refX":"5","refY":"3.5"}},[_c('polygon',{attrs:{"points":"0 0, 10 3.5, 0 7"}})])])]),_vm._l((_vm.uniqueFlowColumns),function(column,index){return _c('div',{key:index,staticClass:"grid-column"},_vm._l((_vm.nodesWithColumn(column)),function(node){return _c('grid-card',{key:node.id,ref:node.id,refInFor:true,staticClass:"grid-card",class:{
          'selected-start': _vm.linkedNodes.start === node,
          'selected-end': _vm.linkedNodes.end === node,
        },attrs:{"id":node.id,"content":node.block ? node.block : node.workflow,"links":_vm.nodeLinks(node),"mouse-setting":_vm.mouseSetting,"node-id":node.id,"type":node.block ? 'block' : 'workflow'},on:{"drawLine":_vm.drawSvg,"selectForLink":function($event){return _vm.selectLink(node)}}})}),1)}),_c('div',{staticClass:"grid-column"},_vm._l((_vm.unlinkedNodes),function(node){return _c('grid-card',{key:node.id,ref:node.id,refInFor:true,staticClass:"grid-card",class:{
          'selected-start': _vm.linkedNodes.start === node,
          'selected-end': _vm.linkedNodes.end === node,
        },attrs:{"id":node.id,"content":node.block ? node.block : node.workflow,"links":_vm.nodeLinks(node),"mouse-setting":_vm.mouseSetting,"node-id":node.id,"type":node.block ? 'block' : 'workflow'},on:{"drawLine":_vm.drawSvg,"selectForLink":function($event){return _vm.selectLink(node)}}})}),1)],2),(_vm.mouseSetting === 'link')?_c('div',{staticClass:"non-conditional-message"},[_vm._v(" Links are not conditional ")]):_vm._e(),(_vm.showLinkModal)?_c('div',{staticClass:"link-container elevation-1"},[_c('div',[_vm._v(" Are you sure you want to link "+_vm._s(_vm.getNodeName(_vm.linkedNodes.start))+" to "+_vm._s(_vm.getNodeName(_vm.linkedNodes.end))+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.createLink}},[_vm._v(" Link")])],1):_vm._e(),(_vm.showLinkDeleteModal)?_c('div',{staticClass:"link-container elevation-1"},[_c('div',[_vm._v(" Are you sure you want remove this link: "+_vm._s(_vm.deleteLinkStartNodeName)+" -> "+_vm._s(_vm.deleteLinkEndNodeName)+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.deleteLink}},[_vm._v(" Delete")])],1):_vm._e(),_c('div',{staticClass:"build_options elevation-1"},[_c('v-btn',{attrs:{"color":_vm.mouseSetting === 'edit' ? 'primary' : 'white',"tile":""},on:{"click":function($event){return _vm.setMouseSetting('edit')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-pencil")]),_vm._v(" Edit ")],1),_c('v-btn',{attrs:{"color":_vm.mouseSetting === 'link' ? 'primary' : 'white',"tile":""},on:{"click":function($event){return _vm.setMouseSetting('link')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-link")]),_vm._v(" Link ")],1),_c('v-btn',{attrs:{"color":_vm.mouseSetting === 'delete' ? 'primary' : 'white',"tile":""},on:{"click":function($event){return _vm.setMouseSetting('delete')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-delete")]),_vm._v(" Delete ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }