<template>
  <div class="ant-glass-background">
    <dynamic-data-table
      :can-delete="true"
      :can-refresh="true"
      :is-loading="userWorkflowsStatus === 'loading'"
      :table-headers="workflowHeaders"
      :table-records="userWorkflows"
      table-title="My Workflows"
      @deleteItem="setupDelete"
      @reloadData="fetchWorkflows"
    >
      <template #item.collections="{ value, rowId, item }">
        <td style="display: flex; flex-direction: column">
          <v-chip
            v-for="collection in item.collections"
            :key="collection.id"
            class="ma-1"
            label
          >
            {{ collection.name }}
          </v-chip>
        </td>
      </template>
      <template #item.build="{ value, rowId, item }">
        <v-btn
          :disabled="item.collections.length > 0"
          :to="{ name: 'workflow-builder', params: { workflowId: item.id } }"
          color="primary"
          x-small
        >
          Build
        </v-btn>
      </template>

      <template #table-actions>
        <v-dialog
          key="add-edit-workflow"
          v-model="workflowDialog"
          max-width="500px"
          @click:outside="closeWorkflowDialogs"
          @keydown.esc="closeWorkflowDialogs"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="primary"
              small
              v-bind="attrs"
              @click="workflowDialog = true"
              v-on="on"
            >
              New Workflow
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">
                {{ workflowItem.id ? 'Edit' : 'Add' }} Workflow
              </span>
            </v-card-title>
            <v-form ref="form" @submit.prevent>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12" sm="6">
                      <v-text-field
                        v-model="workflowItem.name"
                        :rules="[rules.required]"
                        filled
                        label="Name"
                      />
                      <v-text-field
                        v-model="workflowItem.description"
                        :rules="[rules.required]"
                        filled
                        label="Description"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-form>

            <v-card-actions>
              <v-spacer />
              <v-btn color="error" text @click.stop="closeWorkflowDialogs">
                Cancel
              </v-btn>
              <v-btn color="primary" @click.stop="saveWorkflow"> Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </dynamic-data-table>
    <delete-dialog
      :dialog="workflowDeleteDialog"
      :title="`Are you sure you want to remove ${workflowItem.name}?`"
      description="This action removes the workflow and all its blocks and configuration"
      @closeDialog="closeWorkflowDialogs"
      @deleteAction="removeWorkflow"
    />
  </div>
</template>

<script>
import DynamicDataTable from '@/components/DynamicDataTable';
import { mapGetters } from 'vuex';
import DeleteDialog from '@/components/DeleteDialog';

export default {
  name: 'WorkflowManager',
  components: { DeleteDialog, DynamicDataTable },

  data: () => {
    return {
      workflowDeleteDialog: false,
      workflowDialog: false,
      workflowItem: {},
      workflowHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Collections', value: 'collections', hasSlot: true },
        { text: 'Build', value: 'build', hasSlot: true },
        {
          text: 'Actions',
          value: 'actions',
          align: 'right',
          width: '1%',
          sortable: false,
        },
      ],
      rules: {
        required: (value) => !!value || 'Required.',
        maxLength: (value) =>
          (value && value.length < 250) || 'Max 250 characters',
      },
    };
  },
  computed: {
    ...mapGetters(['userWorkflows', 'userWorkflowsStatus']),
  },
  methods: {
    fetchWorkflows() {
      this.$store.dispatch('fetchUserWorkflows');
    },
    closeWorkflowDialogs() {
      this.workflowItem = Object.assign({}, {});
      this.workflowDialog = false;
      this.workflowDeleteDialog = false;
    },
    setupDelete(workflow) {
      this.workflowItem = Object.assign({}, workflow);
      this.workflowDeleteDialog = true;
    },
    removeWorkflow() {
      this.$store
        .dispatch('deleteWorkflow', {
          workflowId: this.workflowItem.id,
        })
        .then(() => {
          this.closeWorkflowDialogs();
        });
    },
    saveWorkflow() {
      // create workflow
      this.$store
        .dispatch('createWorkflow', this.workflowItem)
        .then(this.closeWorkflowDialogs);
    },
  },
};
</script>

<style scoped></style>
