<template>
  <div class="workflow-management-container">
    <transition mode="out-in" name="fade">
      <router-view class="flex-grow-1 flex-scroll-height overflow-y-auto" />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'WorkflowManagement',
  mounted() {
    this.$store.dispatch('fetchUserWorkflows');
  },
};
</script>

<style lang="scss" scoped>
.workflow-management-container {
  padding: 20px;
  display: flex;
}
</style>
