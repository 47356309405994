<template>
  <div v-if="workflow" class="workflow-builder-container">
    <div
      class="ant-header"
      style="position: relative; display: flex; align-items: center"
    >
      <v-btn
        class="elevation-0"
        color="transparent"
        small
        @click="navigateToWorkflow"
      >
        <v-icon left> mdi-chevron-left</v-icon>
        workflows
      </v-btn>
      <span style="flex: 1">{{ workflow.name }}</span>
      <div class="workflow-builder-actions">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon v-bind="attrs" @click="nodeDialog = true" v-on="on">
              mdi-layers-plus
            </v-icon>
          </template>
          <span>Add workflow step</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              @click="publishWorkflowDialog = true"
              v-on="on"
            >
              mdi-publish
            </v-icon>
          </template>
          <span>Publish workflow in collection</span>
        </v-tooltip>
      </div>
    </div>

    <build-grid />

    <create-node-dialog
      :dialog="nodeDialog"
      @closeDialog="nodeDialog = false"
    />

    <publish-workflow-dialog
      :display-dialog="publishWorkflowDialog"
      @closeDialog="publishWorkflowDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BuildGrid from '@/components/WorkflowManagement/Builder/BuildGrid';
import CreateNodeDialog from '@/components/WorkflowManagement/Builder/CreateNodeDialog';
import PublishWorkflowDialog from '@/components/WorkflowManagement/Builder/PublishWorkflowDialog';

export default {
  name: 'WorkflowBuilder',
  components: { PublishWorkflowDialog, CreateNodeDialog, BuildGrid },
  data: () => {
    return {
      nodeDialog: false,
      publishWorkflowDialog: false,
      workflow: undefined,
    };
  },
  computed: {
    ...mapGetters(['userWorkflows']),
  },
  watch: {
    userWorkflows(value) {
      this.workflow = this.userWorkflows.find(
        (workflow) => workflow.id === this.$route.params.workflowId
      );
    },
    workflow() {
      this.fetchWorkflowBuilderData(this.workflow.id);
    },
  },
  mounted() {
    this.workflow = this.userWorkflows.find(
      (workflow) => workflow.id === this.$route.params.workflowId
    );
  },
  methods: {
    fetchWorkflowBuilderData(workflowId) {
      this.$store.dispatch('fetchWorkflowData', workflowId);
    },
    navigateToWorkflow() {
      this.$router.push({ name: 'workflow-manager' });
    },
  },
};
</script>

<style lang="scss" scoped>
.workflow-builder-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .ant-header {
    margin-bottom: 20px;

    .workflow-builder-actions {
      display: flex;

      .v-icon {
        margin-right: 10px;

        &:hover {
          color: var(--v-primary-base);
        }
      }
    }
  }
}
</style>
